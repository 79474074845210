import { CommonModule } from '@angular/common';
import { Component, DestroyRef, HostBinding, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { PlanType } from '@app/+authenticated/+reports/shared/subscriptions/subscription.model';
import { AppState } from '@app/reducers';
import { getAuthenticatedDepartmentTreeOptions } from '@app/reducers/orm/department/department.selectors';
import {
  getSelectedDepartmentIds,
  SelectedDepartmentsService,
} from '@app/reducers/selected-departments/selected-departments.service';
import { SubscriptionPlanDirective } from '@app/shared/subscription-plan/subscription-plan.directive';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ButtonComponent, DropdownToggleDirective, IconComponent, SelectComponent, SelectItem } from '@sb/ui';
import { clsx } from 'clsx';
import { combineLatest, take } from 'rxjs';

@Component({
  selector: 'departments-dropdown',
  templateUrl: './departments-dropdown.component.html',
  standalone: true,
  imports: [
    CommonModule,
    SelectComponent,
    SubscriptionPlanDirective,
    ReactiveFormsModule,
    ButtonComponent,
    IconComponent,
    DropdownToggleDirective,
  ],
})
export class DepartmentsDropdownComponent implements OnInit {
  public departmentCtrl = new FormControl<string[]>([]);
  public buttonLabel;
  public departmentsCount = 0;
  public selectedDepartmentsCount = 0;
  public showPill = false;
  public isMenuOpened = false;
  public departmentOptions: SelectItem[] = [];

  public planType = PlanType;

  @HostBinding('class')
  public get hostClass(): string {
    // Hide the dropdown if there is only one department
    return clsx(this.departmentsCount < 2 ? 'hidden' : 'leading-none');
  }

  public constructor(
    private selectedDepartmentsService: SelectedDepartmentsService,
    private store: Store<AppState>,
    private destroyRef: DestroyRef,
    private translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.buttonLabel = this.translate.instant('Departments');

    void combineLatest([
      this.store.select(getAuthenticatedDepartmentTreeOptions),
      this.store.select(getSelectedDepartmentIds),
    ])
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(([departmentOptions, selectedDepartments]) => {
        this.departmentOptions = departmentOptions;
        this.departmentsCount = this.departmentOptions.reduce((acc, item) => acc + item.children.length || 0, 0);
        this.departmentCtrl.setValue(selectedDepartments, { emitEvent: false });
        this.setButtonContent();
      });

    void this.departmentCtrl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((selectedDepartments) => {
      this.setButtonContent();
      this.selectedDepartmentsService.selectDepartments(selectedDepartments);
    });
  }

  private setButtonContent() {
    this.selectedDepartmentsCount = this.departmentCtrl.value.length;

    switch (this.selectedDepartmentsCount) {
      case 0:
        this.buttonLabel = this.translate.instant('No Departments');
        break;
      case 1:
        this.buttonLabel = this.getDepartmentName();
        break;
      case this.departmentsCount:
        this.buttonLabel = this.translate.instant('All Departments');
        break;
      default:
        this.buttonLabel = this.translate.instant('Departments');
    }

    this.showPill = this.selectedDepartmentsCount > 1 && this.selectedDepartmentsCount !== this.departmentsCount;
  }

  private getDepartmentName(): string {
    const departmentId = this.departmentCtrl.value[0];
    return this.departmentOptions.reduce((name, location) => {
      const department = location.children.find((department) => department.value === departmentId);
      return department ? department.text : name;
    }, '');
  }
}
