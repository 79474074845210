<div class="timesheet__totals">
  <div class="timesheet-totals__time" data-testid="timesheet-total-time">
    {{ time | decimalToTime }}
  </div>
  <ng-container *subscriptionPlan="planType.BASIC">
    @if (showSalary) {
      <div class="timesheet-totals__salary" data-testid="timesheet-total-salary">
        {{ salary | money }}
      </div>
      @if (showCoc) {
        <div class="timesheet-totals__salary" data-testid="timesheet-total-coc">
          {{ coc | money }}
        </div>
      }
    }
  </ng-container>
</div>
