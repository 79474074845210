import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PlanType } from '@app/+authenticated/+reports/shared/subscriptions/subscription.model';
import { ClockMapDialogComponent } from '@app/+modals/timesheet/timesheet-hours/clock-location/clock-map-dialog.component';
import { Features } from '@app/enums';
import { FeatureService } from '@app/startup/feature.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { originOptions } from '@reducers/auth/auth.service';
import { AppState } from '@reducers/index';
import { ConflictType, TimesheetConflictStoreModel } from '@reducers/orm/timesheet-conflict/timesheet-conflict.model';
import { getTimesheetConflictsByTimesheetId } from '@reducers/orm/timesheet-conflict/timesheet-conflict.selectors';
import { TimesheetModelEnchanced } from '@reducers/orm/timesheet/timesheet.model';
import { TimesheetColumns } from '@reducers/page-filters/page-filters.model';
import { LegacyDialogService } from '@sb/ui';
import { Subscription } from 'rxjs';

@Component({
  selector: 'timesheet-details',
  templateUrl: './timesheet-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetDetailsComponent implements OnInit, OnDestroy {
  private _timesheet: TimesheetModelEnchanced;

  public planType = PlanType;

  private hasFeatureFlagViewOwnClockTimePermission = false;

  @Input()
  public absenceWarning: boolean;
  @Input()
  public doubleRegistration: boolean;
  @Input()
  public cols: TimesheetColumns;

  public conflict: TimesheetConflictStoreModel;
  public hasTimesheetConflict: boolean;
  public hasAbsenceConflict: boolean;

  private dataSubs = new Subscription();

  public hasRateCard = false;

  @Input()
  public set timesheet(timesheet: TimesheetModelEnchanced) {
    this._timesheet = {
      ...timesheet,
      canViewClockTime: this.hasFeatureFlagViewOwnClockTimePermission ? timesheet.canViewClockTime : true,
      ClockedInOrigin:
        timesheet.clocked_in_origin && originOptions[timesheet.clocked_in_origin]
          ? this.translate.instant(originOptions[timesheet.clocked_in_origin])
          : timesheet.clocked_in_origin,
      ClockedOutOrigin:
        timesheet.clocked_out_origin && originOptions[timesheet.clocked_out_origin]
          ? this.translate.instant(originOptions[timesheet.clocked_out_origin])
          : timesheet.clocked_out_origin,
      overtimeHour: parseInt(timesheet?.overtime?.split('.')[0]),
      overtimeMinutes: parseInt(this.formatOvertimeMinutes(parseInt(timesheet?.overtime?.split('.')[1]) * 0.6)),
    };

    this.hasRateCard = !!this.timesheet?.Rates?.RateBlock?.find(
      (rateBlock) =>
        !rateBlock?.is_overtime &&
        (parseFloat(rateBlock?.surcharge_pay.toString()) !== 0 ||
          parseFloat(rateBlock?.surcharge_time.toString()) !== 0),
    );
  }

  public get timesheet(): TimesheetModelEnchanced {
    return this._timesheet;
  }

  public constructor(
    private translate: TranslateService,
    private store: Store<AppState>,
    private readonly dialog: LegacyDialogService,
    private readonly featureService: FeatureService,
  ) {}

  public ngOnInit() {
    this.hasFeatureFlagViewOwnClockTimePermission = this.featureService.isEnabled(
      Features.TMP_VIEW_OWN_CLOCKED_TIME_PERMISSION,
    );

    this.dataSubs.add(
      this.store.select(getTimesheetConflictsByTimesheetId(this.timesheet.id)).subscribe({
        next: (timesheetConflict: TimesheetConflictStoreModel) => {
          this.hasTimesheetConflict = timesheetConflict?.conflicts?.some(
            (conflict) => conflict.type === ConflictType.TIMESHEET,
          );
          this.hasAbsenceConflict = timesheetConflict?.conflicts?.some(
            (conflict) => conflict.type === ConflictType.ABSENCE,
          );
        },
      }),
    );
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }

  public showClockMap(): void {
    if (!this.timesheet.canViewClockTime) {
      return;
    }
    this.dialog.open(ClockMapDialogComponent, { data: { timesheet: this.timesheet } });
  }

  public formatOvertimeMinutes(minutes: number): string {
    const formattedMinutes = Math.round(minutes);
    return formattedMinutes < 10 ? `0${formattedMinutes}` : `${formattedMinutes}`;
  }
}
