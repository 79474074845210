import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LocaleDatePipe } from '@app/pipes/locale-date.pipe';
import { TranslationParamsPipe } from '@app/pipes/translation-params.pipe';
import { AppState } from '@app/reducers';
import { AbsenceService } from '@app/reducers/orm/absence/absence.service';
import { CorrectionService } from '@app/reducers/orm/correction/correction.service';
import { Balance } from '@app/reducers/orm/employee/employee.model';
import { EmployeeService, getEmployeeEntities } from '@app/reducers/orm/employee/employee.service';
import { ContentStateComponent } from '@app/shared/content-state/content-state.component';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipDirective } from '@sb/tooltip';
import { IconComponent } from '@sb/ui';
import { subDays } from 'date-fns';
import { combineLatest, merge, take } from 'rxjs';

import { format, parseDate } from '../../../../../shared/date.helper';
import { EmployeeOverviewData } from '../employee-absence-overview.component';
import { TimeOffBalanceComponent } from './time-off-balance/time-off-balance.component';

@Component({
  selector: 'time-off-balances',
  templateUrl: './time-off-balances.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TranslationParamsPipe,
    LocaleDatePipe,
    TimeOffBalanceComponent,
    IconComponent,
    TooltipDirective,
    ContentStateComponent,
  ],
})
export class TimeOffBalancesComponent implements OnChanges {
  @Input()
  public year: string;

  @Input()
  public employee: EmployeeOverviewData;

  public startDate: string;
  public endDate: string;
  public endContractDate?: string;

  public balances: Balance[];
  public balanceStatusMap: Record<string, boolean> = {};
  public loading = false;

  public constructor(
    private employeeService: EmployeeService,
    private correctionService: CorrectionService,
    private absenceService: AbsenceService,
    private store: Store<AppState>,
    private destroyRef: DestroyRef,
  ) {
    void merge(this.correctionService.listenToChanges(), this.absenceService.listenToChanges())
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.getBalances();
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['year']) {
      this.startDate = `${this.year}-01-01`;

      if (this.employee.endDate) {
        this.endContractDate = format(subDays(parseDate(this.employee.endDate), 1), 'yyyy-MM-dd');
      }

      this.endDate =
        this.endContractDate && new Date(this.endContractDate).getFullYear() === Number(this.year)
          ? this.endContractDate
          : `${this.year}-12-31`;
    }

    if (changes['employee'] || changes['year']) {
      this.getBalances();
    }
  }

  public onBalanceToggle(balance: Balance) {
    this.balanceStatusMap[balance.timeOffBalanceId] = !this.balanceStatusMap[balance.timeOffBalanceId];
    this.updateBalanceStatusMap(false, balance.timeOffBalanceId);
  }

  public onCorrectionDeleted() {
    this.getBalances();
  }

  private updateBalanceStatusMap(override?: boolean, skipOverrideFor?: string) {
    if (this.balances?.length) {
      this.balanceStatusMap = this.balances.reduce((map, balance) => {
        map[balance.timeOffBalanceId] =
          override !== undefined && skipOverrideFor !== balance.timeOffBalanceId
            ? override
            : this.balanceStatusMap[balance.timeOffBalanceId] || false;
        return map;
      }, {});
    }
  }

  private getBalances() {
    this.loading = true;
    void combineLatest([
      this.employeeService.getBalances(this.employee.id, this.year),
      this.store.select(getEmployeeEntities),
    ])
      .pipe(take(1))
      .subscribe({
        next: ([balances, employees]) => {
          // Set employee name as createdBy for corrections
          this.balances = balances.map((balance) => {
            balance.corrected.corrections = balance.corrected.corrections.map((correction) => {
              const employee = employees[correction.createdBy];
              return {
                ...correction,
                createdBy: employee ? employee.name : correction.createdBy,
              };
            });

            return balance;
          });

          this.updateBalanceStatusMap();
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }
}
