<div class="flex flex-col rounded-base border border-solid border-grey-100 p-3">
  <div class="flex cursor-pointer items-stretch gap-3" role="button" (click)="toggled.emit()">
    <div class="flex w-4 flex-col gap-0.5 overflow-hidden rounded-base">
      @if (remainingPercentage < 100) {
        <div class="flex-1 bg-grey-200"></div>
      }
      @if (remainingPercentage > 0) {
        <div class="bg-primary-500" [ngStyle]="{ height: remainingPercentage + '%' }"></div>
      }
    </div>
    <div class="flex flex-1 flex-col">
      <p class="m-0 text-sm font-bold text-word-mark-800">{{ balance.timeOffBalanceName }}</p>
      <h5 class="m-0 text-2xl font-bold text-word-mark-800">
        {{ balance.total | decimalToDurationFormat: balance.unit : durationOptions }}
      </h5>
      <p class="m-0 text-xs font-medium text-grey-500">
        {{ 'Remaining' | translate }}
      </p>
    </div>
    <sb-icon
      name="chevron-down"
      class="self-center transition-transform ease-in-out"
      [ngClass]="{ '-rotate-180': isActive }"
    >
    </sb-icon>
  </div>

  @if (expiries?.length) {
    <div class="mt-3 flex flex-col gap-3">
      @for (expiry of expiries; track expiry.date) {
        <sb-notice
          [title]="('To Expire' | translate) + ': ' + expiry.expireAmount"
          [description]="'Use before ' + expiry.formattedDate"
          [variant]="'warning'"
          [size]="'sm'"
        ></sb-notice>
      }
    </div>
  }

  <div
    class="grid transition-collapse duration-300 ease-in-out"
    [ngClass]="{ 'grid-rows-collapse': !isActive, 'mt-3 grid-rows-collapse-expanded': isActive }"
  >
    <div class="flex flex-col gap-3 overflow-hidden">
      @for (partial of partialBalances; track $index) {
        <time-off-partial-balance [employee]="employee" [partial]="partial"> </time-off-partial-balance>
      }
      <hr class="m-0 border-solid border-grey-200" />
      <p class="m-0 flex items-center gap-2 text-sm font-bold text-word-mark-800">
        <span class="size-3 rounded-base bg-primary-500"></span>
        <span class="flex-1">{{ 'Remaining' | translate }}</span>
        {{ balance.total | decimalToDurationFormat: balance.unit : durationOptions }}
      </p>
    </div>
  </div>
</div>
