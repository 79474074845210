<div class="timesheet__actions flex">
  <ng-content></ng-content>
  <a
    *ngIf="timesheet.canEdit || timesheet.canApprove"
    [routerLink]="['', { outlets: { modal: ['timesheet', timesheet.id, 'edit'] } }]"
    class="btn btn-secondary button-icon button-edit inline-flex items-center justify-center"
    [attr.aria-label]="'Edit' | translate"
  >
    <icon class="si" svgIcon="edit"></icon>
  </a>

  <sb-status-button
    *ngIf="timesheet.canApprove"
    [status]="timesheet.status"
    (statusChange)="changeStatus($event)"
    [saving]="timesheet.saving"
    class="ml-1 focus:children:outline-0"
  ></sb-status-button>
  <!-- focus:children:outline-0 is a temp soltion to remove outline from button in timesheets, see https://differentlab.atlassian.net/browse/SHB-6479 -->
  <sb-status-icon
    *ngIf="!timesheet.canApprove"
    [status]="timesheet.status"
    [saving]="timesheet.saving"
    class="ml-1"
  ></sb-status-icon>
</div>

<timesheet-total
  [time]="time"
  [salary]="salary"
  [showSalary]="showSalary"
  [showCoc]="showCoc"
  [coc]="coc"
></timesheet-total>
