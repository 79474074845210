<sidebar class="sidebar">
  <timesheet-sidebar
    [columns]="columns"
    [show]="options?.show"
    [status]="options?.statusFilters"
    [type]="options?.typeFilters"
    [customFieldsColumns]="options?.customFields"
  >
  </timesheet-sidebar>
</sidebar>

<div class="sub-header">
  <div></div>
  <div class="sub-header__navigation">
    <form class="form-inline" [formGroup]="periodForm">
      <div class="form-group mb-0" [class.has-danger]="periodForm.get('period').invalid">
        <label class="sub-header__navigation-select" for="period">{{ 'Period' | translate }}:</label>
        <date-range-picker formControlName="period" [maxDate]="today"></date-range-picker>
      </div>
    </form>
  </div>
</div>

<div class="summary myhours-summary">
  <div class="summary__block">
    <content-state [isLoading]="isLoading" class="summary__block-amount" size="extra-small">
      {{ viewData?.shiftCount }}
    </content-state>
    <span class="flex justify-center gap-1 pb-8 align-middle font-semibold">
      <sb-icon size="lg" [name]="'calendar_approved'"></sb-icon>
      {{ 'Total shifts' | translate }}
    </span>
  </div>
  <div class="summary__block">
    <content-state [isLoading]="isLoading" class="summary__block-amount" size="extra-small">
      {{ viewData?.total.hours | decimalToTime }}
    </content-state>
    <span class="flex justify-center gap-1 pb-8 align-middle font-semibold">
      <sb-icon size="lg" [name]="'stopwatch'"></sb-icon>
      {{ 'Total hours' | translate }}
    </span>
  </div>
  <ng-container *subscriptionPlan="planType.BASIC">
    @if (viewData?.canViewSalary) {
      <div class="summary__block">
        <content-state [isLoading]="isLoading" class="summary__block-amount" size="extra-small">
          {{ viewData?.total.pay | money }}
        </content-state>
        <span class="flex justify-center gap-1 pb-8 align-middle font-semibold">
          <sb-icon size="lg" [name]="'payment'"></sb-icon>
          {{ 'Total salary' | translate }}
        </span>
      </div>
      @if (showCoc) {
        <div class="summary__block">
          <content-state [isLoading]="isLoading" class="summary__block-amount" size="extra-small">
            {{ viewData?.total.coc | money }}
          </content-state>
          <span class="flex justify-center gap-1 pb-8 align-middle font-semibold">
            <sb-icon size="lg" [name]="'calculator'"></sb-icon>
            {{ 'Total CTC' | translate }}
          </span>
        </div>
      }
    }
  </ng-container>
</div>

<div class="card">
  <div class="card-heading">
    <div class="card-heading__title" translate>Worked hours</div>
    <div class="card-heading__button-group flex gap-2">
      <filters-toggle [isActive]="isAnyTimesheetFilterActive$ | async" (toggleClick)="openSidebar()" />

      <div
        *permission="['Create timesheets', 'Create own timesheet']; user: 'me'"
        class="btn btn-primary button-icon-text m-0 flex items-center text-base"
        [routerLink]="['', { outlets: { modal: ['timesheet', { employee: employeeId }] } }]"
      >
        <icon class="si button-icon__icon mr-[10px]" svgIcon="sum"></icon>
        <span class="hidden-xs-down button-icon__title">{{ 'Add worked hours' | translate }}</span>
      </div>
    </div>
  </div>
  <div #cardTable class="card-table" [class.timesheet-card]="viewData?.days.length">
    <content-state
      [isEmpty]="!(viewData?.days).length"
      [isLoading]="isLoading"
      icon="stopwatch"
      size="small"
      [title]="'Nothing found' | translate"
      [content]="'Try adjusting the filters' | translate"
    >
      <ng-container *ngIf="viewData?.days.length">
        <div ScrollWindow class="timesheet">
          <awesome-grid [width]="{ left: widths.info, center: widths.center, right: widths.total }" [showRight]="true">
            <awesome-grid-row FixedHeader [alwaysVisible]="true" class="timesheet-day-header-row hours-overview">
              <timesheet-day-header
                awesome-grid-left
                [width]="widths.info"
                title="{{ 'Date' | translate }}"
                [showAsFooter]="false"
              >
              </timesheet-day-header>

              <timesheet-content-row awesome-grid-center>
                <div class="timesheet-day-header timesheet__status" [ngStyle]="{ 'width.px': widths.status }"></div>
                <div class="timesheet-day-header" [ngStyle]="{ 'width.px': widths.start }" translate>Start</div>
                <div class="timesheet-day-header" [ngStyle]="{ 'width.px': widths.end }" translate>End</div>
                <div class="timesheet-day-header" [ngStyle]="{ 'width.px': widths.break }" *ngIf="columns?.break">
                  {{ 'Break' | translate }}
                </div>
                <div class="timesheet-day-header" [ngStyle]="{ 'width.px': widths.location }" *ngIf="columns?.location">
                  {{ 'Location' | translate }}
                </div>
                <div
                  class="timesheet-day-header"
                  [ngStyle]="{ 'width.px': widths.department }"
                  *ngIf="columns?.department"
                >
                  {{ 'Department' | translate }}
                </div>
                <div
                  class="timesheet-day-header"
                  [ngStyle]="{ 'width.px': widths.team }"
                  *ngIf="columns?.team"
                  translate
                >
                  {{ 'Team' | translate }}
                </div>
                <div class="timesheet-day-header" [ngStyle]="{ 'width.px': widths.shift }" *ngIf="columns?.shift">
                  {{ 'Shift' | translate }}
                </div>
                <div
                  class="timesheet-day-header"
                  [ngStyle]="{ 'width.px': widths.surcharge }"
                  *ngIf="columns?.surcharge"
                >
                  {{ 'Rate card' | translate }}
                </div>
                <div class="timesheet-day-header" [ngStyle]="{ 'width.px': widths.km }" *ngIf="columns?.km" translate>
                  {{ 'Kilometers' | translate }}
                </div>
                <div class="timesheet-day-header" [ngStyle]="{ 'width.px': widths.meals }" *ngIf="columns?.meals">
                  {{ 'Meals' | translate }}
                </div>
                <div class="timesheet-day-header" [ngStyle]="{ 'width.px': widths.note }" *ngIf="columns?.note">
                  {{ 'Note' | translate }}
                </div>
                <ng-container *ngFor="let customField of customFields">
                  <div
                    *ngIf="options?.customFields && (options?.customFields)[customField.id]"
                    class="timesheet-day-header"
                    [ngStyle]="{ 'width.px': customField.width }"
                  >
                    {{ customField.name }}&nbsp;<icon
                      *ngIf="!!customField.description"
                      class="si"
                      svgIcon="info"
                      [sbTooltipPlacement]="'top'"
                      [sbTooltip]="customField.description"
                    ></icon>
                  </div>
                </ng-container>
                <div class="timesheet-day-header" [ngStyle]="{ 'width.px': widths.details }">
                  {{ 'Details' | translate }}
                </div>
              </timesheet-content-row>

              <ng-container awesome-grid-right>
                <!-- Reserved space for the action buttons -->
                <timesheet-day-header class="timesheet__actions mt-0 bg-white" [title]="" [showAsFooter]="false" />

                <div class="timesheet-header-totals">
                  <timesheet-day-header [title]="'Hours' | translate" [showAsFooter]="false" />

                  @if (viewData?.canViewSalary) {
                    <timesheet-day-header [title]="'Salary' | translate" [showAsFooter]="false" />
                  }

                  @if (viewData?.canViewSalary && showCoc) {
                    <timesheet-day-header [title]="'CTC' | translate" [showAsFooter]="false" />
                  }
                </div>
              </ng-container>
            </awesome-grid-row>

            <ng-container *ngFor="let day of viewData?.days; trackBy: trackByDate">
              <ng-container *ngFor="let row of day.rows; let i = index; trackBy: trackByRow" [ngSwitch]="row.type">
                <ng-container *ngSwitchCase="'timesheet'">
                  <awesome-grid-row>
                    <timesheet-day-info
                      awesome-grid-left
                      [date]="day.day"
                      [total]="day.total"
                      [showSalary]="viewData.canViewSalary"
                      [scheduleDiff]="day.scheduleDiff"
                      [show]="i === 0"
                      [last]="i === day.rows.length - 1"
                    >
                    </timesheet-day-info>

                    <timesheet-content-row awesome-grid-center>
                      <timesheet-row-timesheet
                        *ngIf="!row.timesheet.canEdit && !row.timesheet.canApprove"
                        [timesheet]="row.timesheet"
                        [locations]="options?.locationsById"
                        [department]="(options?.departmentsById)[row.timesheet.department_id]"
                        [rateCards]="options?.rateCards"
                        [absenceWarning]="row.absenceWarning"
                        [doubleRegistration]="row.doubleRegistration"
                        [deviationsFromSchedule]="row.deviationsFromSchedule"
                        [cols]="columns"
                        [customFields]="customFields"
                        [customFieldCols]="options.customFields"
                        [widths]="widths"
                      >
                      </timesheet-row-timesheet>

                      <timesheet-row-clocked-in
                        *ngIf="(row.timesheet.canApprove || row.timesheet.canEdit) && row.timesheet.active_clock"
                        [timesheet]="row.timesheet"
                        [schedule]="row.schedule"
                        [departments]="options?.departmentsById"
                        [locations]="options?.locationsById"
                        [departmentOptions]="
                          row.timesheet.user_id == options?.authenticatedUserId
                            ? options?.ownDepartmentOptions
                            : options?.departmentOptions
                        "
                        [teams]="options?.teams"
                        [shifts]="options?.shifts"
                        [rateCards]="options?.rateCards"
                        [absenceWarning]="row.absenceWarning"
                        [doubleRegistration]="row.doubleRegistration"
                        [deviationsFromSchedule]="row.deviationsFromSchedule"
                        [cols]="columns"
                        [customFields]="customFields"
                        [customFieldCols]="options.customFields"
                        [widths]="widths"
                      >
                      </timesheet-row-clocked-in>

                      <timesheet-row-edit
                        *ngIf="(row.timesheet.canApprove || row.timesheet.canEdit) && !row.timesheet.active_clock"
                        [timesheet]="row.timesheet"
                        [schedule]="row.schedule"
                        [departments]="options?.departmentsById"
                        [locations]="options?.locationsById"
                        [departmentOptions]="
                          row.timesheet.user_id == options?.authenticatedUserId
                            ? options?.ownDepartmentOptions
                            : options?.departmentOptions
                        "
                        [teams]="options?.teams"
                        [shifts]="options?.shifts"
                        [rateCards]="options?.rateCards"
                        [absenceWarning]="row.absenceWarning"
                        [doubleRegistration]="row.doubleRegistration"
                        [deviationsFromSchedule]="row.deviationsFromSchedule"
                        [cols]="columns"
                        [customFields]="customFields"
                        [customFieldCols]="options.customFields"
                        [widths]="widths"
                      >
                      </timesheet-row-edit>
                    </timesheet-content-row>

                    <timesheet-content-row awesome-grid-right>
                      <timesheet-total-timesheet
                        *ngIf="!row.timesheet.canEdit && !row.timesheet.canApprove"
                        [timesheet]="row.timesheet"
                        [time]="row.timesheet.total"
                        [salary]="row.timesheet.salary"
                        [showSalary]="viewData.canViewSalary"
                        [coc]="row.timesheet.coc"
                        [showCoc]="showCoc"
                      >
                      </timesheet-total-timesheet>

                      <timesheet-total-timesheet-clocked-in
                        *ngIf="(row.timesheet.canApprove || row.timesheet.canEdit) && row.timesheet.active_clock"
                        [timesheet]="row.timesheet"
                        [time]="row.timesheet.total"
                        [salary]="row.timesheet.salary"
                        [showSalary]="viewData.canViewSalary"
                        [coc]="row.timesheet.coc"
                        [showCoc]="showCoc"
                      >
                      </timesheet-total-timesheet-clocked-in>

                      <timesheet-total-timesheet-edit
                        *ngIf="(row.timesheet.canApprove || row.timesheet.canEdit) && !row.timesheet.active_clock"
                        [timesheet]="row.timesheet"
                        [time]="row.timesheet.total"
                        [salary]="row.timesheet.salary"
                        [showSalary]="viewData.canViewSalary"
                        [coc]="row.timesheet.coc"
                        [showCoc]="showCoc"
                      >
                      </timesheet-total-timesheet-edit>
                    </timesheet-content-row>
                  </awesome-grid-row>
                </ng-container>
                <ng-container *ngSwitchCase="'schedule'">
                  <awesome-grid-row>
                    <timesheet-day-info
                      awesome-grid-left
                      [date]="day.day"
                      [total]="day.total"
                      [showSalary]="viewData.canViewSalary"
                      [scheduleDiff]="day.scheduleDiff"
                      [show]="i === 0"
                      [last]="i === day.rows.length - 1"
                    >
                    </timesheet-day-info>

                    <timesheet-content-row awesome-grid-center>
                      <timesheet-row-schedule
                        [schedule]="row.schedule"
                        [locations]="options?.locationsById"
                        [department]="(options?.departmentsById)[row.schedule.department_id]"
                        [cols]="columns"
                        [widths]="widths"
                      >
                      </timesheet-row-schedule>
                    </timesheet-content-row>

                    <timesheet-total-schedule
                      awesome-grid-right
                      [dayIsClosed]="
                        openCloseData &&
                        openCloseData[day.day] &&
                        openCloseData[day.day][employeeId] &&
                        openCloseData[day.day][employeeId].availableDepartments &&
                        openCloseData[day.day][employeeId].availableDepartments.length === 0
                      "
                      [schedule]="row.schedule"
                      [employeeId]="employeeId"
                    >
                    </timesheet-total-schedule>
                  </awesome-grid-row>
                </ng-container>
                <ng-container *ngSwitchCase="'absentee'">
                  <awesome-grid-row>
                    <timesheet-day-info
                      awesome-grid-left
                      [date]="day.day"
                      [total]="day.total"
                      [showSalary]="viewData.canViewSalary"
                      [scheduleDiff]="day.scheduleDiff"
                      [show]="i === 0"
                      [last]="i === day.rows.length - 1"
                    >
                    </timesheet-day-info>

                    <timesheet-content-row awesome-grid-center>
                      <timesheet-row-absence [absence]="row.absentee" [date]="day.day" [widths]="widths">
                      </timesheet-row-absence>
                    </timesheet-content-row>

                    <timesheet-total-absence
                      awesome-grid-right
                      [absence]="row.absentee"
                      [showSalary]="viewData.canViewSalary"
                      [showCoc]="showCoc"
                    >
                      <div absence-tooltip>
                        {{ 'Total absentee hours' | translate }}: {{ row.absentee?.hours | decimalToTime }} <br />
                        {{ 'Total wait hours' | translate }}: {{ row.absentee?.wait_hours | decimalToTime }}
                      </div>
                    </timesheet-total-absence>
                  </awesome-grid-row>
                </ng-container>
              </ng-container>
            </ng-container>

            <awesome-grid-row class="timesheet-day-total">
              <timesheet-day-header
                awesome-grid-left
                [width]="widths.info"
                title="{{ 'Totals' | translate }}"
                [showAsFooter]="true"
              >
              </timesheet-day-header>

              <timesheet-content-row awesome-grid-center> </timesheet-content-row>

              <timesheet-grand-total
                awesome-grid-right
                [time]="viewData?.total.hours"
                [salary]="viewData?.total.pay"
                [showSalary]="viewData?.canViewSalary"
                [showAsFooter]="true"
                [coc]="viewData?.total.coc"
                [showCoc]="showCoc"
              >
              </timesheet-grand-total>
            </awesome-grid-row>
          </awesome-grid>
        </div>

        <horizontal-scrollbar mode="off-left"></horizontal-scrollbar>
      </ng-container>
    </content-state>
  </div>
</div>
