import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, HostBinding, Input } from '@angular/core';

import { Cell } from '../../../../../shared/awesome-grid/interfaces';

@Component({
  selector: 'timesheet-grand-total',
  templateUrl: './timesheet-grand-total.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: Cell, useExisting: forwardRef(() => TimesheetGrandTotalComponent) }],
})
export class TimesheetGrandTotalComponent extends Cell {
  @Input()
  public time: number;
  @Input()
  public salary: number;
  @Input()
  public showSalary: boolean;
  @Input()
  public showAsFooter: boolean;
  @Input()
  public coc: number;
  @Input()
  public showCoc = false;

  @HostBinding('style.height.px')
  public height = 36;

  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}
