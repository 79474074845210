<div class="timesheet-detail-group">
  <div *subscriptionPlan="planType.BASIC" class="timesheet-detail-group__info">
    <shift-warning
      [absenceWarning]="absenceWarning || hasAbsenceConflict"
      [doubleRegistration]="doubleRegistration || hasTimesheetConflict"
    ></shift-warning>
  </div>

  <div class="timesheet-detail-group__info">
    <ng-container *ngIf="!cols?.break && timesheet.break > 0">
      <ng-template #breakTooltip> {{ 'Break' | translate }}: {{ timesheet.break }} </ng-template>
      <sb-badge [size]="'large'" [color]="'red'" [sbTooltip]="breakTooltip" class="mr-1 last:mr-0 truncate">
        <icon class="button-icon__icon" svgIcon="coffee"></icon>&nbsp;{{ timesheet.break }}
      </sb-badge>
    </ng-container>

    <ng-container
      *ngIf="!cols?.location && timesheet.location_name != timesheet.department_name && !!timesheet.location_name"
    >
      <ng-template #locationTooltip> {{ 'Location' | translate }}: {{ timesheet.location_name }} </ng-template>
      <sb-badge
        [size]="'large'"
        [color]="'indigo'"
        [sbTooltip]="locationTooltip"
        class="max-w-[80px] mr-1 last:mr-0 truncate"
      >
        {{ timesheet.location_name }}
      </sb-badge>
    </ng-container>

    <ng-container *ngIf="!cols?.department && !!timesheet.department_name">
      <ng-template #departmentTooltip> {{ 'Department' | translate }}: {{ timesheet.department_name }} </ng-template>
      <sb-badge
        [size]="'large'"
        [color]="'cyan'"
        [sbTooltip]="departmentTooltip"
        class="max-w-[80px] mr-1 last:mr-0 truncate"
      >
        {{ timesheet.department_name }}
      </sb-badge>
    </ng-container>

    <ng-container *ngIf="!cols?.team">
      <ng-template #teamTooltip> {{ 'Team' | translate }}: {{ timesheet.Team.name }} </ng-template>

      <sb-badge
        [size]="'large'"
        [color]="'greenLight'"
        [sbTooltip]="teamTooltip"
        class="max-w-[80px] mr-1 last:mr-0 truncate"
      >
        {{ timesheet.Team.name }}
      </sb-badge>
    </ng-container>

    <ng-container *ngIf="!cols?.shift">
      <ng-template #shiftTooltip> {{ 'Shift' | translate }}: {{ timesheet.Shift.long_name }} </ng-template>
      <sb-badge
        [size]="'large'"
        [color]="'pink'"
        [sbTooltip]="shiftTooltip"
        class="max-w-[80px] mr-1 last:mr-0 truncate"
      >
        {{ timesheet.Shift.long_name }}
      </sb-badge>
    </ng-container>

    <ng-container *subscriptionPlan="planType.BASIC">
      @if (timesheet.clock) {
        <ng-template #clockTimesTooltip>
          <div *ngIf="!!timesheet.clocked_in">
            {{ 'Clocked in' | translate }}: {{ timesheet.clocked_in | date: 'HH:mm:ss' }}
            <ng-container *ngIf="timesheet.ClockedInOrigin">
              {{ 'via' | translate }} {{ timesheet.ClockedInOrigin }}
            </ng-container>
          </div>

          <ng-container *ngIf="timesheet.ClockBreak?.length > 0">
            <div *ngFor="let clockBreak of timesheet.ClockBreak">
              {{ 'Break' | translate }}: {{ clockBreak.clocked_in | date: 'HH:mm:ss' }} -
              {{ clockBreak.clocked_out | date: 'HH:mm:ss' }}
            </div>
            <div>{{ 'Total break' | translate }}: {{ timesheet.clocked_break | calculateBreakTime }}</div>
          </ng-container>

          <div *ngIf="timesheet.clocked_out">
            {{ 'Clocked out' | translate }}: {{ timesheet.clocked_out | date: 'HH:mm:ss' }}
            <ng-container *ngIf="timesheet.ClockedOutOrigin">
              {{ 'via' | translate }} {{ timesheet.ClockedOutOrigin }}
            </ng-container>
          </div>
        </ng-template>

        <sb-badge
          [size]="'large'"
          [color]="'grey'"
          [sbTooltip]="clockTimesTooltip"
          [sbTooltipDisabled]="!timesheet.canViewClockTime"
          class="mr-1 truncate last:mr-0"
          (click)="showClockMap()"
        >
          <icon class="button-icon__icon" svgIcon="stopwatch"></icon>
        </sb-badge>
      }
    </ng-container>

    <sb-badge
      *ngIf="!cols?.note && !!timesheet.note"
      [size]="'large'"
      [color]="'blue'"
      [sbTooltip]="timesheet.note"
      class="mr-1 last:mr-0"
    >
      <icon class="button-icon__icon" svgIcon="comment"></icon>
    </sb-badge>

    <sb-badge
      *ngIf="timesheet.Shift.is_task"
      [size]="'large'"
      [color]="'blue'"
      [sbTooltip]="
        'This timesheet belongs to a task: [task]' | translate | translationParams: { task: timesheet.Shift.long_name }
      "
      class="mr-1 last:mr-0"
    >
      <icon class="button-icon__icon" svgIcon="clipboard"></icon>
    </sb-badge>

    <ng-container *subscriptionPlan="planType.EARLY_ADOPTER">
      <ng-container *ngIf="!cols?.km && timesheet.kilometers > 0">
        <ng-template #kmTooltip> {{ 'Kilometers' | translate }}: {{ timesheet.kilometers | decimal }} </ng-template>

        <sb-badge [size]="'large'" [color]="'purple'" [sbTooltip]="kmTooltip" class="mr-1 last:mr-0">
          <icon class="button-icon__icon" svgIcon="car"></icon>&nbsp;{{ timesheet.kilometers | decimal }}
        </sb-badge>
      </ng-container>

      <ng-container *ngIf="!cols?.meals && timesheet.meals > 0">
        <ng-template #mealTooltip> {{ 'Meals' | translate }}: {{ timesheet.meals }} </ng-template>

        <sb-badge [size]="'large'" [color]="'yellow'" [sbTooltip]="mealTooltip" class="mr-1 last:mr-0">
          <icon class="button-icon__icon" svgIcon="cutlery"></icon>&nbsp;{{ timesheet.meals }}
        </sb-badge>
      </ng-container>
    </ng-container>

    <ng-container *subscriptionPlan="planType.BASIC">
      <ng-container
        *ngIf="
          ((timesheet.Rates?.surcharge_pay !== 0 || timesheet.Rates?.surcharge_time !== 0) && hasRateCard) ||
          (timesheet.overtimeHour && timesheet.overtimeHour !== 0) ||
          (timesheet.overtimeMinutes && timesheet.overtimeMinutes !== 0)
        "
      >
        <ng-template #surchargeTooltip>
          <div>
            <ng-container *ngIf="!timesheet.RateCard?.deleted && hasRateCard" class="block">
              {{ 'Rate card' | translate }}: {{ timesheet.RateCard?.name }}
            </ng-container>
            <ng-container *ngIf="timesheet.RateCard?.deleted" class="block">
              {{ '[name] was deleted at: ' | translate | translationParams: { name: timesheet.RateCard?.name } }}
              {{ timesheet.RateCard?.deleted_date | date: 'P' }}
            </ng-container>
          </div>
          <div>
            <ng-container
              *ngIf="
                (timesheet.overtimeHour && timesheet.overtimeHour !== 0) ||
                (timesheet.overtimeMinutes && timesheet.overtimeMinutes !== 0)
              "
            >
              {{ 'Overtime' | translate }}: {{ timesheet.overtimeHour }}h {{ timesheet.overtimeMinutes }}m
            </ng-container>
          </div>
        </ng-template>

        <div
          class="btn button-icon"
          [ngClass]="{
            'bg-grey-200': !timesheet.RateCard?.deleted,
            'bg-error-500 text-white hover:text-white': timesheet.RateCard?.deleted
          }"
          [sbTooltip]="surchargeTooltip"
          [routerLink]="['', { outlets: { modal: ['rates', timesheet.id] } }]"
        >
          <icon class="button-icon__icon" svgIcon="flag"></icon>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
