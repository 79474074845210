import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PlanType } from '@app/+authenticated/+reports/shared/subscriptions/subscription.model';
import { DecimalToTimePipe } from '@app/pipes/decimal-to-time.pipe';
import { MoneyPipe } from '@app/pipes/money.pipe';
import { SubscriptionPlanDirective } from '@app/shared/subscription-plan/subscription-plan.directive';

@Component({
  selector: 'timesheet-total',
  templateUrl: './timesheet-total.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MoneyPipe, DecimalToTimePipe, SubscriptionPlanDirective, NgClass, NgStyle],
})
export class TimesheetTotalComponent {
  @Input()
  public time: number;
  @Input()
  public salary: number;
  @Input()
  public showSalary: boolean;
  @Input()
  public totalRow: boolean;
  @Input()
  public coc: number;
  @Input()
  public showCoc = false;

  public readonly planType = PlanType;
}
