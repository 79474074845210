<!-- remove outline from button in timesheets -->
<div class="timesheet__actions flex items-center gap-1 focus:children:outline-0">
  <!-- currently clocked in ? -->
  @if (timesheet.active_clock && timesheet.canClockOut) {
    <button sb-button [sbLadda]="timesheet.saving" [color]="'red'" (click)="handleClockOut()">
      {{ 'Clock out' | translate }}
    </button>
  } @else {
    <sb-status-icon [status]="timesheet.status"></sb-status-icon>
  }
</div>

<timesheet-total
  [time]="time"
  [salary]="salary"
  [showSalary]="showSalary"
  [coc]="coc"
  [showCoc]="showCoc"
></timesheet-total>
