import {
  Component,
  DestroyRef,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { getAccountCocInSchedule } from '@app/reducers/account/account.service';
import { PermissionOption } from '@app/reducers/orm/permission/permission.model';
import { debounce } from '@app/shared/decorator/debounce.decorator';
import { select, Store } from '@ngrx/store';
import { subDays } from 'date-fns';
import { BehaviorSubject, Subscription } from 'rxjs';
import { combineLatest, filter, startWith, switchMap, tap } from 'rxjs/operators';

import { ValidationService } from '../../../../forms/validation.service';
import { AppState } from '../../../../reducers/index';
import { CustomFieldsAppliesTo, CustomFieldsModel } from '../../../../reducers/orm/custom-fields/custom-fields.model';
import { getCustomFieldsByAppliesTo } from '../../../../reducers/orm/custom-fields/custom-fields.selectors';
import { TimesheetService } from '../../../../reducers/orm/timesheet/timesheet.service';
import { TimesheetColumns } from '../../../../reducers/page-filters/page-filters.model';
import { isAnyTimesheetFilterActive, PageFiltersService } from '../../../../reducers/page-filters/page-filters.service';
import { format, parseDate } from '../../../../shared/date.helper';
import { HorizontalScrollService } from '../../../../shared/horizontal-scroll/horizontal-scroll.service';
import { lazySelect } from '../../../../shared/lazy-select.observable';
import { PlanType } from '../../../+reports/shared/subscriptions/subscription.model';
import { TimesheetDayService } from '../../../+timesheet/shared/day/timesheet-day-service';
import { TimesheetHelperService } from '../../../+timesheet/shared/timesheet-helper.service';
import { SidebarComponent } from '../../../shared/sidebar/sidebar.component';
import {
  getTimesheetDepartmentsForUsers,
  getTimesheetPageOptionsForAllDepartments,
} from './../../../+timesheet/shared/timesheet-helper.service';
import { getDataForEmployeeTimesheetPage } from './employee-hours.helper';
import { EmployeeTimesheetData } from './interfaces';

@Component({
  selector: 'employee-hours-overview',
  templateUrl: './employee-hours-overview.component.html',
  styleUrls: ['./employee-hours-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [TimesheetDayService],
})
export class EmployeeHoursOverviewComponent implements OnInit, OnDestroy {
  @Input()
  public title: string;

  private employeeChange$: BehaviorSubject<any> = new BehaviorSubject({});
  private _employeeId;

  public today = format(new Date(), 'yyyy-MM-dd');

  public periodForm = this.fb.group({
    period: new UntypedFormControl(
      [format(subDays(new Date(), 7), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')],
      [Validators.required, ValidationService.rangePickerPeriodValidator(true), ValidationService.dateValidator],
    ),
  });

  public planType = PlanType;

  private subscription: Subscription = new Subscription();

  private requestData;
  public openCloseData;

  public viewData: EmployeeTimesheetData;
  public options;
  public columns: TimesheetColumns;
  public isLoading = true;

  public widths;
  public customFields: CustomFieldsModel[] = [];

  public isAnyTimesheetFilterActive$ = this.store.select(isAnyTimesheetFilterActive);

  @ViewChild('cardTable', { static: true })
  public cardTable: ElementRef;
  @ViewChild(SidebarComponent, { static: true })
  public sidebar: SidebarComponent;

  public showCoc: boolean;

  @HostListener('window:resize', ['$event'])
  @debounce()
  public onResize() {
    this.calculateWidths();
  }

  public constructor(
    private store: Store<AppState>,
    private fb: UntypedFormBuilder,
    private pageFiltersService: PageFiltersService,
    private helper: TimesheetHelperService,
    private horizontalScrollService: HorizontalScrollService,
    private timesheetDayService: TimesheetDayService,
    private timesheetService: TimesheetService,
    private readonly destroyRef: DestroyRef,
  ) {}

  @Input()
  public set employeeId(employee) {
    this._employeeId = employee;
    this.employeeChange$.next(employee);
    this.pageFiltersService.setFilter('timesheet', 'employeeId', employee);
    this.horizontalScrollService.determineScrollbarWidth();
  }

  public get employeeId() {
    return this._employeeId;
  }

  public ngOnInit() {
    this.isLoading = true;

    this.loadData();
    this.getViewData();

    this.getOptions();

    this.subscription.add(
      this.timesheetDayService.status$
        .pipe(
          filter(({ timesheet }) => !!timesheet),
          // For some reason we need to manually `save` here and subscribe.
          // otherwise the request will get cancelled straight away.
          switchMap(({ timesheet, status }) => this.timesheetService.save({ id: timesheet.id, status })),
        )
        .subscribe(),
    );

    this.subscription.add(
      this.store
        .select(getCustomFieldsByAppliesTo(CustomFieldsAppliesTo.TIMESHEET))
        .subscribe((customFields: CustomFieldsModel[]) => {
          this.customFields = customFields;
        }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Make sure the data for the given period and user is loaded into the store
   */
  private loadData() {
    this.subscription.add(
      this.employeeChange$
        .pipe(
          combineLatest(
            this.periodForm.valueChanges.pipe(
              startWith(this.periodForm.value),
              filter(() => this.periodForm.valid),
            ),
            (employeeId, formData) => ({
              employeeId,
              minDate: parseDate(formData.period[0]),
              maxDate: parseDate(formData.period[1]),
            }),
          ),
          tap((data) => {
            this.requestData = {
              minDate: format(data.minDate, 'yyyy-MM-dd'),
              maxDate: format(data.maxDate, 'yyyy-MM-dd'),
            };
            this.pageFiltersService.setFilter('timesheet', 'period', this.requestData);
          }),
          switchMap((params) => this.helper.loadDataObs(params.minDate, params.maxDate, params.employeeId)),
          switchMap(() => {
            const permissions: PermissionOption = ['Create timesheets', 'Create own timesheet'];
            return this.store.pipe(
              select(
                getTimesheetDepartmentsForUsers(
                  [this.employeeId],
                  this.requestData.minDate,
                  this.requestData.maxDate,
                  permissions,
                ),
              ),
            );
          }),
        )
        .subscribe((openCloseData) => {
          this.openCloseData = openCloseData;
        }),
    );
  }

  private getViewData() {
    this.subscription.add(
      this.store.pipe(lazySelect(getDataForEmployeeTimesheetPage)).subscribe((data) => {
        this.viewData = data;
        if (data && data.days) {
          this.isLoading = false;
        }
      }),
    );
  }

  private getOptions() {
    this.subscription.add(
      this.store
        .select(getAccountCocInSchedule)
        .pipe(
          tap((showCoc) => {
            this.showCoc = showCoc;
          }),
          switchMap(() => this.store.pipe(lazySelect(getTimesheetPageOptionsForAllDepartments))),
        )
        .subscribe((options) => {
          // Handle options
          this.options = options;
          this.columns = options.columns;

          // Calculate widths here.
          this.calculateWidths();
        }),
    );
  }

  private calculateWidths() {
    const widths = this.timesheetDayService.calculateWidths(
      this.cardTable,
      this.columns,
      this.options.customFields,
      this.customFields,
    );

    // 400 is set as an override for the total width, this same value is used in the scss.
    // This is a temporary solution until we can refactor the grid system.
    // If the value needs to be updated also update the scss.
    this.widths = { ...widths, total: 400 };
  }

  public trackByDate(index, dayRow) {
    return dayRow.day;
  }

  public trackByRow(index, row) {
    const rowType = row.type;
    const model = row[rowType];

    return row.type + ':' + model.id;
  }

  public openSidebar() {
    this.sidebar.open();
  }
}
