import { ElementRef, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { CustomFieldsModel } from '../../../../reducers/orm/custom-fields/custom-fields.model';
import { TimesheetModel } from '../../../../reducers/orm/timesheet/timesheet.model';
import { TimesheetColumns, TimesheetCustomFieldsColumns } from '../../../../reducers/page-filters/page-filters.model';

// At this size the entire table can be seen.
export const centerTotal = 1579;
// The widths of all optional columns.
const optionalColumnWidths = Object.freeze({
  break: 70,
  location: 120,
  department: 134,
  team: 112,
  shift: 127,
  surcharge: 300,
  km: 84,
  meals: 73,
  note: 132,
});

// Widths of all required columns.
const requiredColumns = Object.freeze({
  status: 4,
  start: 72,
  end: 72,
  details: 249,
});

// The widths of all columns which are not on the left and right.
const timesheetCenterWidths = Object.freeze({
  ...requiredColumns,
  ...optionalColumnWidths,
});

// All widths combined.
const widths = Object.freeze({
  info: 219,
  total: 300,
  ...timesheetCenterWidths,
  center: 0,
  absenceCenter: 0,
  scheduleCenter: 0,
  customFields: 0,
});

interface TimesheetStatus {
  timesheet: TimesheetModel;
  status: string;
}

@Injectable()
export class TimesheetDayService {
  // The totals and rows need to be able to send status updates to each other.
  public status$ = new Subject<TimesheetStatus>();

  // Some widths need to be calculated, based on the visible columns.
  public calculateWidths(
    element: ElementRef,
    columns: TimesheetColumns,
    customFieldsColumns = null,
    customFields: CustomFieldsModel[] = [],
  ) {
    const nextWidths = { ...widths };

    const visibleCustomFieldsColumnsWidth = this.calculateVisibleCustomFieldsColumnsWidth(
      customFields,
      customFieldsColumns,
    );
    const visibleCenterColumnsWidth = this.visibleCenterColumnsWidth(columns) + visibleCustomFieldsColumnsWidth;

    // @ts-ignore
    nextWidths.absenceCenter = this.calculateAbsenceCenter(columns);
    // @ts-ignore
    nextWidths.scheduleCenter = this.calculateScheduleCenter(columns);

    // @ts-ignore
    nextWidths.center = this.calculateCenter(element, visibleCenterColumnsWidth);

    // @ts-ignore
    nextWidths.details = this.calculateDetail(nextWidths.center, visibleCenterColumnsWidth);

    return nextWidths;
  }

  // Detail should take the space left over by the visible center columns.
  private calculateDetail(center: number, visibleCenterColumnsWidth: number): number {
    return center - visibleCenterColumnsWidth + widths.details;
  }

  private calculateVisibleCustomFieldsColumnsWidth(
    customFields: CustomFieldsModel[],
    columns: TimesheetCustomFieldsColumns,
  ) {
    let customFieldsWidth = 0;
    if (columns) {
      customFieldsWidth = customFields
        .filter((customField) => columns[customField.id])
        .map((customField) => customField.width)
        .reduce((acc, width) => acc + width, 0);
    }

    return customFieldsWidth;
  }

  /*
    Center should take the full screen when possible if the screen is very large.
    If the screen becomes smaller it should set the minimum to the visible center columns.
    If there are very few columns lock it to 1000px, which guarantees that we show enough details.
  */
  private calculateCenter(element: ElementRef, visibleCenterColumnsWidth: number): number {
    // use getBoundingClientRect() instead of clientWidth, which returns a value of
    // 0 in some cases in Edge
    const elementWidth = element.nativeElement.getBoundingClientRect().width;
    const center = elementWidth - widths.info - widths.total - 2;

    return Math.max(center, visibleCenterColumnsWidth, 800);
  }

  // All visible center columns widths added together
  private visibleCenterColumnsWidth(columns: TimesheetColumns): number {
    return Object.keys(timesheetCenterWidths)
      .filter((key) => {
        const visible = columns[key];
        return visible === true || visible === undefined;
      })
      .map((key) => widths[key])
      .reduce((acc, width) => acc + width, 0);
  }

  // The center column of the absence should take the size of all visible optional columns
  private calculateAbsenceCenter(columns: TimesheetColumns): number {
    return Object.keys(optionalColumnWidths)
      .filter((key) => columns[key])
      .map((key) => optionalColumnWidths[key])
      .reduce((acc, width) => acc + width, 0);
  }

  // The center column of the schedule should take the size of all columns it does not display
  private calculateScheduleCenter(columns: TimesheetColumns): number {
    return ['surcharge', 'km', 'meals', 'note']
      .filter((key) => columns[key])
      .map((key) => optionalColumnWidths[key])
      .reduce((acc, width) => acc + width, 0);
  }
}

// Check the sizes the help the developer, but do not do this in production.
if (!environment.production) {
  const totalCenter = Object.keys(timesheetCenterWidths)
    .map((key) => timesheetCenterWidths[key])
    .reduce((acc, width) => acc + width, 0);

  if (totalCenter !== centerTotal) {
    const missing = centerTotal - totalCenter;

    console.warn(
      `TimesheetDay: expected timesheet widths of center to total ${centerTotal} pixels but was: ${totalCenter} missing ${missing} pixels.`,
    );
  }
}
