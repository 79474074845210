<div
  class="timesheet-grid timesheet-grand-total"
  [class.timesheet-grand-total--footer]="showAsFooter"
  [ngStyle]="{ 'height.px': height }"
>
  <div class="timesheet__actions"></div>

  <timesheet-total
    [time]="time"
    [salary]="salary"
    [showSalary]="showSalary"
    [totalRow]="true"
    [coc]="coc"
    [showCoc]="showCoc"
  ></timesheet-total>
</div>
